$accent-font: 'Montserrat', sans-serif;
#footer {
    background: #000;
    padding:30px 0;
    color: #eee;
    font-size: 14px;
  
    .footer-top {
      padding-top: 30px;
      font-size: 18px;
  
      .footer-info {
        margin-bottom: 30px;
        
        h3 {
          font-size: 50px;
          margin: 0 0 20px 0;
          padding: 2px 0 2px 10px;
          line-height: 1;
          font-family: $accent-font;
          font-weight: 700;
          letter-spacing: 3px;
          border-left: 4px solid var(--accent-color);
        }
  
        p {
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 0;
          font-family: $accent-font;
          color: #eee;
        }
      }
  
      .social-links {
        a {
          font-size: 20px;
          display: inline-block;
          background: #333;
          color: #eee;
          line-height: 1;
          padding: 8px 0;
          margin-right: 4px;
          border-radius: 50%;
          text-align: center;
          width: 36px;
          height: 36px;
          transition: 0.3s;
          &:hover {
            background: var(--accent-color);
            color: #fff;
          }
        }
      }
  
      h4 {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        position: relative;
        padding-bottom: 12px;
      }
  
      h4::before, h4::after  {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
      }
  
      h4::before {
        right: 0;
        background: #555;
      }
  
      h4::after {
        background: var(--accent-color);
        width: 60px;
      }
  
      .footer-links {
        margin-bottom: 30px;
        ul{
          list-style: none;
          padding: 0;
          margin: 0;
  
          i {
            padding-right: 8px;
            color: #ddd;
          }
  
          li {
            border-bottom: 1px solid #333;
            padding: 10px 0;
            &:first-child {
              padding-top: 0;
            }
          }
  
          a {
            color: #eee;
            &:hover {
              color: var(--accent-color);
            }
          }
        }
      }
  
      .footer-contact {
        margin-bottom: 30px;
        p {
          line-height: 26px;
        }
      }
  
      .footer-newsletter {
        margin-bottom: 30px;
  
        input[type="email"] {
          border: 0;
          padding: 6px 8px;
          width: 65%;
        }
        input[type="submit"] {
          background: var(--accent-color);
          border: 0;
          width: 35%;
          padding: 6px 0;
          text-align: center;
          color: #fff;
          transition: 0.3s;
          cursor: pointer;
        }
      }
  
    }
  
    .copyright {
      text-align: center;
      padding-top: 30px;
    }
  
    .credits {
      text-align: center;
      font-size: 13px;
      color: #ddd;
    }
  }