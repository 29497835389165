
#process {
    background: #F0F0F0;
    background-size: cover;
    padding-top: 3rem;
  
    .process-description {
      text-align: center;
      font-size: 18px;
    }
  
    .row {
      justify-content: space-between;
  
      .span3{
        width: 100%;
        margin-bottom: 20px;
        border-bottom: 1px solid #eee;
  
        .service-box {
          text-align: center;
          margin-bottom: 25px;
          img{
            max-width: 225px;
            transition: .3s;
          }
  
          h2 {
            padding-top: 20px;
            text-align: center;
          }
        
          p {
            padding: 20px;
            text-align: left;
            width: 100%;
            margin: 0 auto;
            font-size: 18px;
          }

          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }
        &:last-child {
          border-bottom: none;
        }
  
        @media (min-width: 650px) {
          width: 50%;
  
          margin-bottom: 10px;
          border-bottom: none;
        }
  
        @media (min-width: 1023px) {
          width: 33%;
          border-bottom: none;
        }

        @media (min-width: 1600px) {
          width: 16.5%;
          border-bottom: none;
        }

        @media (max-width: 768px) {
          animation: none !important;
        }
      }
    }
  }