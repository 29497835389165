.sidebar {
    color: #fff;
    width: 35%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
    margin-top: 5rem;
    display: none;
    h4 {
        color: #c2c0c0;
        font-weight: 500;
        margin: 0;
        font-style: italic;
        width: 90%;
        margin-bottom: 30px;
        position: relative;
        display: inline-block;
        font-size: 1.3rem;
        &::before {
            content: '"';
            font-size: 2em; /* adjust size as needed */
            position: absolute;
            left: -20px; /* adjust position as needed */
            top: -8px;
        }
        &::after {
            content: '"';
            font-size: 2em; /* adjust size as needed */
            position: absolute;
            bottom: -15px;
        }
    }

    h2 {
        color: #fff;
        font-size: 6rem;
        text-transform: capitalize;
        width: 100%;
        margin: 1rem 0;
    }

    p {
        line-height: 30px;
        width: 70%;
        margin-bottom: 3rem;
    }

    a {
        display: block;
        padding: .8rem 1rem;
        border-radius: 30px;
        color: #fff;
        border: 2px solid var(--accent-color);
        max-width: 200px;
        text-align: center;
        text-decoration: none;
        transition: .3s;
        font-weight: 700;
        margin-top: 1.5rem;


        &:hover {
            background-color: var(--accent-color);
            color: #fff;
        }
    }

    @media (min-width: 1199px) {
        display: block;
        width: 35%;
        min-width: 35%;
        margin-top: 20%;

        h2 {
            font-size: 4rem;
        }

        p {
            width: 80% ;
            font-size: 16px;
            line-height: 26px;
        }
    }

    @media (min-width: 1999px) {
        margin-top: 15%;
    }
}



