#Parallax1 {
    background: var(--accent-color);
    background-size: cover;
    padding: 30px 0;
    max-height: 400px;
  
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
  
      .one {
        width: 200px;
      }
    
      .two{
        width: 300px;
        height: auto;
      }
  
      @media (min-width: 550px) {
        flex-direction: row;
        justify-content: center;
      }

      @media (min-width: 767px) {
        .one {
            width: 300px;
        }

        .two {
            width: 400px;
        }
      }
  
      @media (min-width: 1023px) {
        .one {
          width: 300px;
        }
        .two {
            width: 550px;
        }
      }
      @media (min-width: 1600px) {
        gap: 10rem;
      }
    }
  }