:root {
  --accent-color: #038af2;
}

body {
  width: 100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
}

.App {
    background: linear-gradient(-45deg, #000, var(--accent-color),  #000 );
    background-size: 450% 200%;
    background-position: center left;
    animation: gradient 20s ease infinite;
    height: 100%;
    overflow: hidden;
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }

  #tsparticles {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: inherit;
    opacity: .5;
}

.container {
  max-width: 1800px !important;
  padding: 0 1rem !important;

  @media (min-width: 767px) {
    padding: 0 2rem !important;
  }
}


.background-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-size: cover;
  background-position: center;
  opacity: .5;
}

.section-header {
  h3 {
    font-size: 32px;
    color: #111;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
  }

  h3::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  h3::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: var(--accent-color);
    bottom: 0;
    left: calc(50% - 20px);
  }

  p {
    text-align: center;
    padding-bottom: 30px;
    color: #333;
  }
}

.back-to-top {
  transition: .2s;
  background-color: var(--accent-color);
  &:hover {
    transform: scale(1.15);
  }
}

