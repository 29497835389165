#contact {
    background: #fff;
    padding: 40px 0;
  
    .contact-info {
      margin-bottom: 20px;
      text-align: center;

      .col-md-4 {
        border-top:1px solid #f0f0f0;
        transition: .3s;
        i {
            font-size: 80px;
            display: inline-block;
            color: var(--accent-color);
          }
      
          address, p {
            margin-bottom: 0;
            color: #000;
          }
      
          h3 {
            font-size: 20px;
            margin-bottom: 15px;
            font-weight: bold;
            text-transform: uppercase;
            color: #000;
          }
      
          a {
            color: #000;
            &:hover {
              color: var(--accent-color);
            }
        }
        &:first-child {
            border-top: none;
        }
        @media (min-width: 767px) {
            border-top: none;
        }

        &:hover {
            transform: scale(1.2);
            border-top: none;
        }
      }
    }
  
    .contact-address, .contact-phone, .contact-email {
      margin-bottom: 20px;
      @media (min-width: 768px) {
        padding: 20px 0;
      }
    }
  
    @media (min-width: 768px) {
      .contact-phone {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
      }
    }
  
    form {
      margin-top: 3rem;
      width: 100%;

      .contact-section-one {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        input {
          width: 100%;
          height: 50px;
          padding-left: 1rem;
        }
      }

      .contact-section-two {
        margin-top: 2rem;

        textarea {
          min-height: 125px;
          width: 100%;
          padding-left: 1rem;
          padding-top: 1rem;
        }
      }

      .button {
        width: 100%;
        text-align: center;

        button {
          border: 2px solid #fff;
          padding: 12px 50px;
          border-radius: 50px;
          transition: 0.3s;
          margin: 30px 0 0;
          transition: 0.4s;
          cursor: pointer;
          color: #fff;
          background:var(--accent-color);
          width: 200px;

          &:hover {
            filter:brightness(.85);
          }
        }
      }


    }

    @media (min-width: 767px) {
      .contact-info {
        display: flex;
      }

      form {
        max-width: 1400px;
        margin: 0 auto;
        .contact-section-one {
          flex-direction: row;
        }
      }
    }

    .error {
      color: red;
      font-size: 0.8em;
      margin-top: 5px;
      font-weight: 700;
    }
  }