// .landing-page-container{
//   animation: none !important;
// }

.landing-page {
    display: flex;
    gap: .5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    
  .react-reveal.name, .react-reveal.socials {
      animation-delay: 1s !important;
    }

  .name {
    color: #fff;
    font-weight: 500;
    text-align: left;
    opacity: .6 !important;
    margin: 0;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 10px;
    font-size: 26px;
    transition: .2s;

    &:hover {
      opacity: 1 !important;
    }
  }
    
  .text-wrapper {
      border-top:1px solid rgba($color: #fff, $alpha: 0.5);
      border-bottom:1px solid rgba($color: #fff, $alpha: 0.5);
      width: 70%;
      margin: 0 auto;
      max-width: 800px;
      position: relative;
      font-family: 'Montserrat', sans-serif;

      h2 {
          margin: 10px 0 10px 0;
          color: #fff;
          font-size: 60px;
          line-height: 60px;
          padding: 0;
          font-weight: 500;
          position: relative;
          transform: scale(1.2);
          text-shadow:1px 2px 10px rgba($color: #000000, $alpha: .2);
      }

      .mobile-text {
        text-align: center;
        font-size: 30px;
        line-height: 45px;
        padding: 5px 0 15px;
      }

      h2.intro-1 {
          text-align: left;
          animation: slide-right 2s;
          display: none;
      }

      h2.intro-2 {
          text-align: right;
          animation: slide-left 2s;
          display: none;
      }
  }

  .socials {
    padding-top: 15px;
    text-align: center;
    width: 100%;
    max-width: 800px;
    width: 100%;
    display: flex;
    gap: 1.3rem;
    justify-content: center;
    
    a {
      opacity: .5;
      color: #fff;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s;
      &:hover {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }

  .arrow-down{
    position: absolute;
    bottom: 13%;

    a {
      color: #fff;
      opacity: .7;
    }

    &:hover {
      a {
        color: #fff;
        opacity: 1;

        cursor: pointer;
      }
    }

    @media  (max-height:700px) {
      bottom: 5% !important;
    }
  }

  @media (min-width: 450px) {
    .landing-page {
      .text-wrapper {
        .mobile-text {
          font-size: 50px !important;
          line-height: 70px;
          padding-bottom: 10px;
        }
      }
  
      .arrow-down {
        bottom: 13%;
      }
    }
  }

  @media (min-width: 500px) {
    .name {
      font-size: 30px;
    }
    .text-wrapper {
      .mobile-text {
        font-size: 50px;
        line-height: 65px;
      }
    }

    .arrow-down {
      bottom: 10%;
    }
  }

  @media (min-width: 767px) {
    width: 100%;

    .name {
      text-align: left;
      width: 70%;
    }

    .text-wrapper {
      .mobile-text {
        display: none;
      }

      .intro-1 {
        display: block !important;
        padding-bottom: 15px;
        padding-top: 10px;
        font-size: 55px;
      }

      .intro-2 {
        display: block !important;
        padding-bottom: 15px;
        font-size: 55px;
      }
    }

    .socials {
      justify-content: flex-end;
      width: 70%;
    }

    .arrow-down {
      bottom: 15%;
      
      a svg {
        transition: .2s;
      }

      &:hover {
        a svg {
          transform: scale(1.2);

        }
      }
    }
  }

  @media (min-width: 1023px) {
    .text-wrapper {
      h2 {
        font-size: 70px !important;
        line-height: 80px;
      }
    }

    .arrow-down {
      bottom: 10%;
    }
  }

  @media (min-width: 1200px) {
    .text-wrapper {
      h2 {
        font-size: 80px !important;
        line-height: 90px;
      }
    }

    .arrow-down {
      bottom: 10%;
    }
  }

  @keyframes slide-left {
    from {
      left: 100%;
      width: 100%; 
    }
  
    to {
      left: 0%;
      width: 100%;
    }
  }
  
  @keyframes slide-right{
      from {
        right: 100%;
        width: 100%; 
      }
    
      to {
        right: 0%;
        width: 100%;
      }
  }
}







