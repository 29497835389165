#header {
  position: absolute;
  padding: 15px;
  top: 15px;
  left: 0;
  width: 100%;
  transition: all 0.5s;
  background: transparent;
  margin: 0 auto;
  z-index: 1000;

  &.sticky {
    top: 0;
    position: fixed;  
    background-color: #000;
  }

  .container-fluid {
    display: flex;
    justify-content: space-between;
    max-width: 2000px;
    margin: 0 auto;
  }

  #logo {
    cursor: pointer;
    
    h1 {
      font-size: 30px;
      padding-bottom: 10px;
      position: relative;
      margin-bottom: 0;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: #fff;
        animation: drawBorder 5s infinite;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: var(--accent-color);
        animation: drawBorder 5s infinite;
      }
      a {
        color: white;
        text-decoration: none;
      }
    }
  }

  #nav-menu-container {
    display: none;  // Hide by default

    &.open {
      display: block;  // Show when menuOpen state is true
      
      ul.nav-menu {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.9);
        width: 80%;
        height: 100vh;
        color: white;
        transform: translateX(0);
        z-index: 999;
        transition: all 0.5s;
        padding-top: 6rem;
        list-style-type: none;

        li {
          padding: 15px 15px 15px 0;
          border-top: 1px solid #f0f0f0;
          cursor: pointer;

          &:first-child {
            border: none;
          }

          a {
            font-size: 1.6rem;
            color: white !important;
            text-decoration: none;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          &:hover {
            border-bottom: 1px solid var(--accent-color) !important;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      display: flex;  // Show the nav menu on larger screens
      align-items: center;
      width: 100%;
      max-width: 600px;

      ul.nav-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: transparent;
        width: 100%;
        height: auto;
        transform: none;
        padding-top: 0;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          padding: 0;
          border: none;
          font-size: 20px;
          cursor: pointer;
          
          a {
            color: #fff;
          }

          &:hover {
            border-bottom: 1px solid var(--accent-color);
          }
        }
      }
    }
  }

  .menu-icon {
    display: none;
    z-index: 999;

    @media (max-width: 1023px) {
      display: block;
    }

    i {
      font-size: 24px;
      cursor: pointer;
      width: 50px;
      height: 50px;
    }
  }
}

#nav-menu-container ul.nav-menu li {
  position: relative;  
  list-style-type: none;
  padding: 10px;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    background-color: #fff;
    transition: width 0.3s ease
  }

  &:hover {
    border-bottom: none;

    &::before {
      width: 100%;  
    }
  }
}
@keyframes drawBorder {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}



