/* Styles for the Portfolio component */
.portfolio {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-position: center;
    position: relative;
    background-attachment: fixed;
    height: 100vh;    

    /* Styles for the Slider and PortfolioItem components */
      .portfolio-items {
        position: relative;
        max-width: 100%;
        flex: 0 0 100%; 
        box-sizing: border-box;
        margin-top: 10rem;
    
        .slick-list {
            width: 100%;
    
            .slick-slide {
                .portfolio-item {
                    background-color: rbga(0, 0, 0, .5);
                    height: 300px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: Top;
                    position: relative;
                    box-sizing: border-box; 
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    // border: 1px solid #fff;
                    margin: 20px;
    
                     h3 {
                        position: absolute;
                        font-weight: 700;
                        color: #fff;
                        z-index: 1;
                        bottom: -40px;
                        padding: 2rem 0;
                        width: 100%;
                        font-size: 30px;
                        text-align: center;
                    } 
    


                  @media (min-width: 1023px) {
                    &.active {
                      transition: .5s;
                      transform: scale(1.1);
                    }
                  }
                }  
            }
        }
    }
    
    .slick-prev,
    .slick-next {
      top: 100%;
      position: absolute;
      font-size: 50px; /* Adjust size as needed */
      color: transparent;
      border: 1px solid #fff;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      z-index: 1;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    
    .slick-prev {
        transform: none;
        // top: 104%;
        top: 110%;
        left: 5%;
    }
    
    .slick-next {
        top: 119%;
        right: 5%;
    }
    
    .slick-dots {
        top: 135%;
    
        li button:before {
            color: #fff;
        }
    } 
    .slick-dots .slick-active button::before {
        color: #fff !important;
    }

    &::before {
        content: "";
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8); // Black overlay with 50% opacity
        z-index: 0; // Ensure it's below the actual content
      }
    
      > * {
        position: relative; // Ensure the actual content is above the overlay
        z-index: 1;
      }
  }

  @media (min-width: 400px) {
    .portfolio-items {
        .portfolio-item {
            height: 400px !important;
            h3{
                bottom: -40px;
            }
        } 
    } 
  }

  @media (min-width: 550px) {
    .portfolio-items {
        .portfolio-item {
            height: 450px !important;
            h3{
                bottom: -40px;
            }
        } 
    }
    .slick-prev {
        top: 100% !important;
    }
    .slick-next {
        top: 108% !important;
    }

    .slick-dots {
        top: 120% !important;
    
        li button:before {
            color: #fff;
        }
    } 
  }

  @media (min-width: 1200px) {
    .portfolio {
        flex-direction: row;

        .portfolio-items {
            margin-top: 0rem;

            h3 {
                display: none;
            }
        }
    }

    .slick-prev {
        transform: none;
        margin-left: 30px;
        top: 105% !important;
    }
    
    .slick-next {
      left: 200px;
      top: 113% !important;
    }
    
    .slick-dots {
        top: 110% !important;
    
        li button:before {
            color: var(--accent-color);
        }
    }
  }

  @media (min-width: 1500px) {

    .slick-prev {
        transform: none;
        margin-left: 30px;
        top: 105% !important;
    }
    
    .slick-next {
      left: 200px;
      top: 113% !important;
    }
    
    .slick-dots {
        top: 110% !important;
    
        li button:before {
            color: #fff;
        }
    }
  }

  @media (min-width: 1600px) {

    .slick-prev {
        transform: none;
        margin-left: 30px;
        top: 105% !important;
    }
    
    .slick-next {
      left: 250px;
      top: 113% !important;
    }
    
    .slick-dots {
        top: 110% !important;
    
        li button:before {
            color: #fff;
        }
    }
  }

  @media (min-width: 1998px) {
    .portfolio .portfolio-item {
        margin-top: 10rem !important;
    }
    .slick-next {
      left: 250px !important;
      top: 100.33% !important;
    }

    .slick-prev {
        top: 94% !important;
      }
    
    .slick-dots {
        top: 97% !important;
    
        li button:before {
            color: #fff;
        }
    }
  }

  @media (min-width: 1999px) {
    /* Styles for the Portfolio component */
.portfolio {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 100vh; /* Assuming you want it to take the full viewport height */

    .sidebar {
        flex: 0 0 30%; /* This makes the sidebar take up 30% of the parent's width */
        padding: 20px; /* Add some padding for better appearance */
        box-sizing: border-box; /* Include padding and border in the element's total width and height */
      }
      
      /* Styles for the Slider and PortfolioItem components */
      .portfolio-items {
        position: relative;
        flex: 0 0 70%; 
        box-sizing: border-box; 
    
        .slick-list {
            width: 70%;
    
            .slick-slide {
                .portfolio-item {
                    background-color: rbga(0, 0, 0, .5);
                    height: 400px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: Top;
                    position: relative;
                    box-sizing: border-box; 
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    // border: 1px solid #fff;
                    margin: 20px;
    
                     h3 {
                        position: absolute;
                        font-weight: 700;
                        color: #fff;
                        z-index: 1;
                        bottom: -20px;
                        padding: 2rem 0;
                        width: 100%;
                        font-size: 30px;
                        text-align: center;
                    } 
    
                    &.active {
                        transition: .5s;
                        transform: scale(1.1);
                      }
                }  
            }
        }
    }
    
    .slick-prev,
    .slick-next {
      top: 100%;
      position: absolute;
      font-size: 50px; /* Adjust size as needed */
      color: transparent;
      border: 2px solid #fff;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      z-index: 1;

    }
    
    .slick-prev {
        transform: none;
        // top: 104%;
        margin-left: 30px;
        top: 110%;
    }
    
    .slick-next {
        top: 119%;
      left: 100px;
    //   top: 113%;
    }
    
    .slick-dots {
        top: 115%;
    
        li button:before {
            color: #fff;
        }
    } 
    .slick-dots .slick-active button::before {
        color: #fff !important;
    }
  }
}

.tabs {
    position: absolute;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    z-index: 10;
    top: 5%;

    button {
      background: transparent;
      color: #fff;
      width: 200px;
      border: none;
      padding: 20px;
      font-size: 20px;

      &:first-child {
        border: none;
      }
    }

    button.active {
      background-color: var(--accent-color);
      color: #fff;
      border: 1px solid #fff;
    }
    
    @media (min-width:1199px) {
      left: 5%;
      justify-content: flex-start;
  }

    @media (min-width:1500px) {
      top: 5%;
  }

      @media (min-width:2000px) {
        top: 7%;
    }
  }



  

  
  


  
  