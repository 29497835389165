#services {
    background-size: cover;
    background-color: #F0F0F0;
    padding: 60px 0 40px 0;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 9;
    }
  
    .container {
      position: relative;
      z-index: 10;
    }
  
    .about-col {
      width: 100%;
      background: #fff;
      border-radius: 0 0 4px 4px;
      box-shadow: 0px 2px 12px rgba(0,0,0,.08);
      margin-bottom: 20px;
  
      .img {
        overflow: hidden;
        position: relative;
        border-radius: 4px 4px 0 0;

        img {
          transition: .2s;
          border-radius: 4px 4px 0 0;


        }
      }
  
      .icon {
        display: none;
        width: 75px;
        height: 75px;
        padding-top: 8px;
        text-align: center;
        position: absolute;
        animation: gradient 15s ease infinite;
        background: var(--accent-color);
        background-size: 400%;
        border-radius: 50%;
        text-align: center;
        border: 4px solid #fff;
        left: calc( 50% - 35px);
        transition: 0.3s;
  
        i {
          font-size: 45px;
          line-height: 1;
          color: #fff;
          transition: 0.3s;
        }
  
        @media (min-width: 1023px) {
          display: block;
          top: 30%;
        }
        @media (min-width: 1104px) {
          top: 33%;
        }
        @media (min-width: 1200px) {
          top: 35%;
        }
        @media (min-width: 1250px) {
          top: 39%;
        }
        @media (min-width: 1350px) {
          top: 41%;
        }
        @media (min-width: 1450px) {
          top: 45%;
        }
        @media (min-width: 1550px) {
          top: 49%;
        }
        @media (min-width: 1600px) {
          top: 50%;
        }
        @media (min-width: 1700px) {
          top: 52%;
        }
        @media (min-width: 1700px) {
          top: 54%;
        }
      }
  
      h2 {
        color: #000;
        text-align: center;
        font-weight: 700;
        font-size: 26px;
        padding: 0;
        margin: 20px 0 12px 0;
  
        @media (min-width: 767px) {
          font-size: 22px;
        }
  
        @media (min-width: 1023px) {
          font-size: 24px;
          margin: 40px 0 12px 0;
        }
      }
  
      p {
        font-size: 16px;
        line-height: 24px;
        color: #333;
        margin-bottom: 0;
        padding: 0 20px 20px 20px;
      }

      &:hover {
        img {
          transform: scale(1.2) !important;
        }
        .icon {
          background: #fff;
          
        }
  
        i {
          color: var(--accent-color);
        }
  
        span {
          color: var(--accent-color);
        }
      }
    }
  }