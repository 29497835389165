.color-picker-container {
    position: relative;
    display: none;

    .color-picker-icon {
      cursor: pointer;
      color: #fff;
      padding-top: 4px;
      height: 25px;
      width: 25px;
    }
  
    .color-picker {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 1000;

      button {
        width: 100%;
        font-size: 14px;
        border: 1px solid #000;
        top: 0;
        background-color: #038AF2;
        color: #fff;
      }
    }

    @media (min-width: 1023px) {
      display: inline-block;
    }
  }
  